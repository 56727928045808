var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, size: "small", inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "作品名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: "请输入作品名称", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.name,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "审核状态", prop: "approveStatusArray" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "260px" },
                  attrs: { multiple: "", placeholder: "请选择审核状态" },
                  model: {
                    value: _vm.listQuery.approveStatusArray,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "approveStatusArray", $$v)
                    },
                    expression: "listQuery.approveStatusArray"
                  }
                },
                _vm._l(_vm.approveStatuseMap, function(key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作品分类", prop: "storyclassId" } },
            [
              _c("treeselect", {
                staticStyle: { width: "240px" },
                attrs: {
                  options: _vm.storyclassOptions,
                  normalizer: _vm.normalizer,
                  placeholder: "选择作品分类"
                },
                model: {
                  value: _vm.listQuery.storyclassId,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "storyclassId", $$v)
                  },
                  expression: "listQuery.storyclassId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作品导航类型", prop: "categoryId" } },
            [
              _c("treeselect", {
                staticStyle: { width: "240px" },
                attrs: {
                  options: _vm.categoryOptions,
                  normalizer: _vm.normalizer,
                  placeholder: "选择作品导航类型"
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.listQuery.categoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "categoryId", $$v)
                  },
                  expression: "listQuery.categoryId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/manuscript/works/list"],
                      expression: "['GET /admin/manuscript/works/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["POST /admin/manuscript/works/create"],
                      expression: "['POST /admin/manuscript/works/create']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "作品ID", prop: "id", width: "80" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品名称",
              prop: "worksName",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品封面",
              prop: "worksCover",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.worksCover
                      ? _c("img", {
                          staticClass: "pointer",
                          attrs: {
                            src: _vm.baseUrl + scope.row.worksCover,
                            width: "40",
                            height: "40"
                          },
                          on: {
                            click: function($event) {
                              return _vm.previewImage(scope.row.worksCover)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品分类",
              prop: "storyclassName",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品导航分类",
              prop: "categoryName",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品价格",
              prop: "worksPrice",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "关键字",
              prop: "worksKeyword",
              "min-width": "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "标签名称",
              prop: "tagName",
              width: "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品类型",
              prop: "systemType",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.systemType == 0
                      ? _c("el-tag", [_vm._v("社内")])
                      : _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("社外")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "审核状态",
              prop: "approveStatus",
              width: "95"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.approveStatus == 0
                      ? _c("el-tag", [_vm._v("申请中")])
                      : scope.row.approveStatus == 1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("审核通过")
                        ])
                      : scope.row.approveStatus == 2
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("审核拒绝")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              align: "center",
              prop: "createTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "class-name": "small-padding fixed-width",
              "min-width": "200",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.approveStatus !== 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "GET /admin/manuscript/works/read/{manuscriptId}",
                                  "PUT /admin/manuscript/works/update"
                                ],
                                expression:
                                  "['GET /admin/manuscript/works/read/{manuscriptId}', 'PUT /admin/manuscript/works/update']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleUpdate(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.systemType == 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "DELETE /admin/manuscript/works/delete/{id}"
                                ],
                                expression:
                                  "['DELETE /admin/manuscript/works/delete/{id}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-delete"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [
                              "GET /admin/manuscript/works/read/{manuscriptId}"
                            ],
                            expression:
                              "['GET /admin/manuscript/works/read/{manuscriptId}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-tickets"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    scope.row.isLock
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "PUT /admin/manuscript/works/validated/{manuscriptId}"
                                ],
                                expression:
                                  "['PUT /admin/manuscript/works/validated/{manuscriptId}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-unlock"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleUnlock(scope.row)
                              }
                            }
                          },
                          [_vm._v("解锁")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.approveStatus === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "POST /admin/manuscript/works/add/sale/{manuscriptId}"
                                ],
                                expression:
                                  "['POST /admin/manuscript/works/add/sale/{manuscriptId}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-plus"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleAddSale(scope.row)
                              }
                            }
                          },
                          [_vm._v("添加销售作品")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "1000px",
            "close-on-press-escape": false,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "940px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "right",
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleTabsClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基本信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "作品名称",
                                    prop: "worksName"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.worksName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataForm, "worksName", $$v)
                                      },
                                      expression: "dataForm.worksName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "作品分类",
                                    prop: "storyclassId"
                                  }
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      options: _vm.storyclassOptions,
                                      normalizer: _vm.normalizer,
                                      placeholder: "选择作品分类"
                                    },
                                    on: { select: _vm.handleSelect },
                                    model: {
                                      value: _vm.dataForm.storyclassId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "storyclassId",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.storyclassId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "作品导航分类",
                                    prop: "categoryId"
                                  }
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      options: _vm.categoryOptions,
                                      normalizer: _vm.normalizer,
                                      placeholder: "选择作品导航分类"
                                    },
                                    on: { select: _vm.handleSelect },
                                    model: {
                                      value: _vm.dataForm.categoryId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "categoryId",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.categoryId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "作品标签", prop: "tagId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "350px" },
                                      attrs: { placeholder: "请选择作品标签" },
                                      model: {
                                        value: _vm.dataForm.tagId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.dataForm, "tagId", $$v)
                                        },
                                        expression: "dataForm.tagId"
                                      }
                                    },
                                    _vm._l(_vm.tagList, function(item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关键字",
                                    prop: "worksKeyword"
                                  }
                                },
                                [
                                  _vm._l(_vm.keywords, function(tag) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: tag,
                                        attrs: {
                                          closable: "",
                                          type: "primary"
                                        },
                                        on: {
                                          close: function($event) {
                                            return _vm.handleClose(tag)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tag) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.newKeywordVisible
                                    ? _c("el-input", {
                                        ref: "newKeywordInput",
                                        staticClass: "input-new-keyword",
                                        attrs: { size: "small" },
                                        on: { blur: _vm.handleInputConfirm },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleInputConfirm(
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.newKeyword,
                                          callback: function($$v) {
                                            _vm.newKeyword = $$v
                                          },
                                          expression: "newKeyword"
                                        }
                                      })
                                    : _c(
                                        "el-button",
                                        {
                                          staticClass: "button-new-keyword",
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: { click: _vm.showInput }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  +增加\n                "
                                          )
                                        ]
                                      )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "创作时间", prop: "addTime" }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "请选择创作时间",
                                      "value-format": "yyyy-MM-dd HH:mm:ss"
                                    },
                                    model: {
                                      value: _vm.dataForm.addTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataForm, "addTime", $$v)
                                      },
                                      expression: "dataForm.addTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "入展时间", prop: "showTime" }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "请选择入展时间",
                                      "value-format": "yyyy-MM-dd HH:mm:ss"
                                    },
                                    model: {
                                      value: _vm.dataForm.showTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataForm, "showTime", $$v)
                                      },
                                      expression: "dataForm.showTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "其他时间",
                                    prop: "otherTime"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "请选择其他时间",
                                      "value-format": "yyyy-MM-dd HH:mm:ss"
                                    },
                                    model: {
                                      value: _vm.dataForm.otherTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataForm, "otherTime", $$v)
                                      },
                                      expression: "dataForm.otherTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "作品价格",
                                    prop: "worksPrice"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "350px" },
                                    attrs: { precision: 2, step: 0.1, min: 0 },
                                    model: {
                                      value: _vm.dataForm.worksPrice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "worksPrice",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.worksPrice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "作品作者",
                                    prop: "authorLibraryId"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        placeholder: "请选择作者",
                                        readonly: true
                                      },
                                      model: {
                                        value: _vm.dataForm.authorName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "authorName",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.authorName"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search"
                                        },
                                        on: { click: _vm.handleSelectAuthor },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.dialogStatus == "create" ||
                      (_vm.dialogStatus == "update" &&
                        _vm.dataForm.systemType == 0)
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "作品稿件",
                                        prop: "originalPaths"
                                      }
                                    },
                                    [
                                      _c("FileUpload", {
                                        attrs: {
                                          uploadUrl: _vm.uploadWorksPath,
                                          params: {},
                                          fileSize: 100,
                                          limit: 1,
                                          value: _vm.dataForm.originalPaths
                                        },
                                        on: { input: _vm.getUploadFileUrl }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "作品介绍", name: "2" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品封面", prop: "worksCover" } },
                        [
                          _c("ImageUpload", {
                            attrs: {
                              uploadImgUrl: _vm.uploadPath,
                              value: _vm.dataForm.worksCover,
                              limit: 1,
                              fileSize: 1
                            },
                            on: { input: _vm.getWorksCoverUrl }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品简介", prop: "worksDesc" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 8 },
                            model: {
                              value: _vm.dataForm.worksDesc,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "worksDesc", $$v)
                              },
                              expression: "dataForm.worksDesc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.authorDialogVisible,
            title: "选择作者",
            width: "1000px"
          },
          on: {
            "update:visible": function($event) {
              _vm.authorDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "queryAuthorForm",
              attrs: { model: _vm.authorListQuery, size: "small", inline: true }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作者名称", prop: "authorName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入作者名称", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleAuthorFilter($event)
                      }
                    },
                    model: {
                      value: _vm.authorListQuery.authorName,
                      callback: function($$v) {
                        _vm.$set(_vm.authorListQuery, "authorName", $$v)
                      },
                      expression: "authorListQuery.authorName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作者分类", prop: "categoryId" } },
                [
                  _c("treeselect", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      options: _vm.categoryOptions,
                      normalizer: _vm.normalizer,
                      placeholder: "选择作者分类"
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.authorListQuery.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.authorListQuery, "categoryId", $$v)
                      },
                      expression: "authorListQuery.categoryId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.handleAuthorFilter }
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetAuthorQuery }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.authorListLoading,
                  expression: "authorListLoading"
                }
              ],
              ref: "authorTable",
              attrs: {
                data: _vm.authorList,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              },
              on: { "row-click": _vm.handleSingleElection }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "55", label: "选择" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radio",
                            attrs: { label: scope.row.authorLibraryId },
                            model: {
                              value: _vm.authorLibItem.authorLibraryId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.authorLibItem,
                                  "authorLibraryId",
                                  $$v
                                )
                              },
                              expression: "authorLibItem.authorLibraryId"
                            }
                          },
                          [_vm._v(" ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者ID",
                  prop: "authorLibraryId",
                  width: "90",
                  sortable: ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者名称",
                  prop: "authorName",
                  "min-width": "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系方式",
                  prop: "authorTel",
                  width: "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者分类",
                  prop: "categoryId",
                  "min-width": "100",
                  formatter: _vm.formatAuthorCategoryName
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者简介",
                  prop: "authorDesc",
                  "min-width": "150",
                  "show-overflow-tooltip": true
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                  width: "150"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.authorTotal > 0,
                expression: "authorTotal > 0"
              }
            ],
            attrs: {
              total: _vm.authorTotal,
              page: _vm.authorListQuery.page,
              limit: _vm.authorListQuery.size
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.authorListQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.authorListQuery, "size", $event)
              },
              pagination: _vm.getAuthorList
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "op-container",
              staticStyle: { "margin-top": "40px" }
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.authorDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmSelectAuthor }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.imageDialogVisible
        ? _c("AuthImgPreview", {
            attrs: { src: _vm.preImageUrl, visible: _vm.imageDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.imageDialogVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }